define("monitor-ui/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "monitor-ui/config/environment", "monitor-ui/utils/session-manager", "jquery"], function (_exports, _emberData, _dataAdapterMixin, _environment, _sessionManager, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _jquery.default.ajaxSetup({
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  });

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    intl: Ember.inject.service(),
    authorizer: 'authorizer:token',
    namespace: 'api',
    host: _environment.default.backendAPI,
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tenantGuardedRoutes: _environment.default['active-tenant-guard'].guardedRoutes,
    loadedModels: {
      'country': false,
      'language': false,
      'industry-standard': false,
      'service-standard': false,
      'company-size-standard': false,
      'company-size-turnover': false,
      'project-goal': false,
      'salutation': false,
      'business-type': false
    },

    shouldReloadAll(store, snapshotArray) {
      const modelName = snapshotArray.type.modelName;
      const loadedModels = this.loadedModels;

      if (modelName in loadedModels) {
        if (loadedModels[modelName]) {
          return false;
        } else {
          loadedModels[modelName] = true;
        }
      }

      return true;
    },

    shouldBackgroundReloadAll(store, snapshotArray) {
      const loadedModels = this.loadedModels;
      return !(snapshotArray.type.modelName in loadedModels);
    },

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      if (url[url.length - 1] !== '/' && url.indexOf('?') === -1) {
        url = url + '/';
      }

      return url;
    },

    updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord'); //add queryParams to url

      if (snapshot.adapterOptions) {
        for (const key in snapshot.adapterOptions) {
          if (snapshot.adapterOptions.hasOwnProperty(key)) {
            const separator = url.indexOf('?') === -1 ? '?' : '&';
            url += separator + key + '=' + snapshot.adapterOptions[key];
          }
        }
      }

      return this.ajax(url, 'PUT', {
        data: data
      });
    },

    createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      }); //add queryParams to url

      if (snapshot.adapterOptions) {
        for (const key in snapshot.adapterOptions) {
          if (snapshot.adapterOptions.hasOwnProperty(key)) {
            const separator = url.indexOf('?') === -1 ? '?' : '&';
            url += separator + key + '=' + snapshot.adapterOptions[key];
          }
        }
      }

      return this.ajax(url, 'POST', {
        data: data
      });
    },

    urlForFindAll(query) {
      var url = this._super(...arguments);

      if (query === 'quote') {
        url += '/?include=companies';
      }

      return url;
    },

    handleResponse(status, headers, payload) {
      const translatedErrorKeys = {
        'This field is required.': 'requiredField',
        'This field is required. You have to select at least one option.': 'requiredFieldProjectPhase',
        'The project start date must occur after the project contract award date': 'startDateAfterAwardDate',
        'Project End date must be after Project Start Date': 'endDateAfterStartDate',
        'Permission denied': 'permissionDenied',
        'Beauty Contest Invitation is not possible': 'beautyContestInvitationNotPossible',
        'Beauty Contest Presentation start is before Evaluation Due': 'beautyContestStartBeforeEvaluation',
        'Beauty Contest Presentation End is after Beauty Contest Due': 'beautyContestEndAfterBeautyContestDue',
        'Proposal due date limit passed': 'proposalDueDatePassed',
        'RfP release date should be at least today.': 'rfpReleaseAtLeastToday',
        'Missing file': 'missingFile',
        'Question Round 1 is not open': 'roundOneNotOpen',
        'Question Round 2 is not open': 'roundTwoNotOpen',
        'Not all proposals have been rated': 'notAllProposalsRated',
        'Beauty contest response denied': 'beautyContestResponseDenied',
        'You have to upload your cover letter first.': 'customCoverLetterError',
        'Your email is not yet confirmed.': 'emailNotConfirmed',
        'User profile must be completed before being able to send out the RfP.': 'incompleteProfile',
        'Your account was not yet validated by an agent.': 'notValidatedByAgent',
        'Enter a valid email address.': 'invalidEmailAddress',
        'Phone number must be entered in the format: \'+999999999\'. Up to 15 digits allowed.': 'invalidPhone',
        'The total of criteria evaluation weight should be 100%': 'invalidCriteriaEvaluationWeight' //TODO: Catch missing errors from translationErrors using sentry

      };

      if (status === 400 && payload.errors) {
        const newListErrors = [];
        payload.errors.forEach(error => {
          if (error.hasOwnProperty('detail')) {
            let newError = {
              detail: error.detail,
              source: error.source,
              status: error.status,
              data: error.data
            };

            if (newError.detail === 'This field may not be blank.' || newError.detail === 'This field may not be null.') {
              newError.detail = 'This field is required.';
            } // if (newError.detail in translatedErrorKeys) {
            //   newError.detail = this.intl.t(translatedErrorKeys[newError.detail]).toString()
            // }


            newListErrors.push(newError);
          }
        });
        return new _emberData.default.InvalidError(newListErrors);
      }

      const router = this.get('router');
      const transition = router._router._routerMicrolib.activeTransition;

      if (transition) {
        const targetRouteUrl = _sessionManager.default.computeUrlFromTransition(transition);

        (0, _sessionManager.inferredTenantRedirect)(headers, targetRouteUrl);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});